const NAME = 'experimentalFeatures';

const EXPERIMENTAL_FEATURE_TYPE = {
  DAMAGE_TYPE_MISMATCH_VERIFICATION_ERROR: 0,
};

export default {
  NAME,
  EXPERIMENTAL_FEATURE_TYPE
};
