import { useEffect, useState } from 'react';
import { constants } from '..';
import { useLoadExperimentalFeatures } from '../../experimentalFeatures';

const getInitialValues = (vehicleType, imageType) => {
  if (vehicleType && imageType) {
    return constants[constants.VEHICLE_ERROR_TYPES[vehicleType]][imageType];
  }

  return Object.keys(constants.IMAGE_VERIFICATION_ERROR).map((key) => constants.IMAGE_VERIFICATION_ERROR[key]);
};

const useImageTypeErrors = (vehicleType, imageType) => {
  const [imageTypeErrors, setImageTypeErrors] = useState(getInitialValues(vehicleType, imageType));
  const { EXPERIMENTAL_FEATURE_TYPE, showExperimentalFeature, experimentalFeaturesLoaded } = useLoadExperimentalFeatures();

  useEffect(() => {
    if (experimentalFeaturesLoaded && imageTypeErrors) {
      setImageTypeErrors(imageTypeErrors.filter((error) => {
        if (error === constants.IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH
          && !showExperimentalFeature(EXPERIMENTAL_FEATURE_TYPE.DAMAGE_TYPE_MISMATCH_VERIFICATION_ERROR)) {
          return false;
        }
        return true;
      }));
    }
  // eslint-disable-next-line
  }, [experimentalFeaturesLoaded]);

  return imageTypeErrors || [];
};

export default useImageTypeErrors;
