/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { VehicleDamagedIndicator, SkeletonUI } from '..';
import { constants as damagesConstants } from '../../redux/damages';
import { constants as workflowsConstants } from '../../redux/workflows';

import { ReactComponent as IconCaretRight } from '../../assets/img/icn-caret-right.svg';
import { ReactComponent as IconCaretDown } from '../../assets/img/icn-caret-down.svg';

import { makeGetFile } from '../../api/ajax';

import './sass/photo-series-damages.scss';

const DamageRow = ({ photoSeries, bodyPart, damages }) => {
  const [open, setOpen] = useState(false);
  const repairEstimation = photoSeries.repairEstimations.find((re) => re.bodyPart === bodyPart);
  const getRepairDecisionName = (repairDecision) => repairDecision === undefined || repairDecision === null
    ? '-'
    : damagesConstants.REPAIR_DECISION_NAME[repairDecision];

  const getRepairDecisionConfidence = (repairDecisionConfidence) => !repairDecisionConfidence
    ? '-'
    : `${repairDecisionConfidence * 100}%`;

  return (
    <div className="damages-table__row-wrapper">
      <div className="damages-table__row" role="row" onClick={() => setOpen(!open)} tabIndex={0}>
        <div className="damages-table__row-part">
          { !open ? <IconCaretRight /> : <IconCaretDown /> }
          <span>{damagesConstants.BODY_PART_NAMES[bodyPart]} ({damages.length})</span>
        </div>
        <div className="damages-table__row-repair-decision">{getRepairDecisionName(repairEstimation?.repairDecision)}</div>
        <div className="damages-table__row-repair-decision-confidence">{getRepairDecisionConfidence(repairEstimation?.confidence)}</div>
      </div>
      {
        open && damages.map((damage, index) => (
          <div className="damages-table__row-sub-row" key={damage.damageId}>
            <span>{index + 1}. {damage.customDamageType?.name || damagesConstants.DAMAGE_TYPE_NAME[damage.damageType]}</span>
            <span>{getRepairDecisionName(damage.repairDecision)}</span>
            <span>{getRepairDecisionConfidence(damage.repairDecisionConfidence)}</span>
          </div>
        ))
      }
    </div>
  );
};

DamageRow.propTypes = {
  bodyPart: PropTypes.number.isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    bodyPart: PropTypes.number,
    damageType: PropTypes.number,
    customDamageTypeName: PropTypes.string
  })).isRequired,
  photoSeries: PropTypes.object.isRequired
};

const Damages = ({ photoSeries, damages }) => {
  const damagesGrouped = _.groupBy(damages, ({ bodyPart }) => bodyPart);
  return (
    <div>
      <div className="damages-table__header">
        <span>Part</span>
        <span>Repair decision</span>
        <span>Confidence</span>
      </div>
      {
        Object.keys(damagesGrouped)?.map((key) => <DamageRow
          key={key}
          bodyPart={parseInt(key, 0)}
          damages={damagesGrouped[key]}
          photoSeries={photoSeries}
        />)
      }
    </div>
  );
};

Damages.propTypes = {
  damages: PropTypes.arrayOf(PropTypes.shape({
    bodyPart: PropTypes.number,
    damageType: PropTypes.number
  })).isRequired,
  photoSeries: PropTypes.object.isRequired
};

const PhotoSeriesDamagesSkeleton = () => (
  <div className="photo-series-damages-container photo-series-card">
    <div className="photo-series-damages-container__row1">
      <div className="photo-series-damages-container__row1-col1">
        <span className="key">Vehicle damages</span>
      </div>
    </div>
    <div className="two-col-skeleton-ui-wrapper">
      <div className="pt-3">
        <SkeletonUI type="radius-horizontal" height="1rem" />
        <SkeletonUI type="radius-horizontal" height="1rem" />
        <SkeletonUI type="radius-horizontal" height="1rem" />
        <SkeletonUI type="radius-horizontal" height="1rem" />
        <SkeletonUI type="radius-horizontal" height="1rem" />
      </div>
      <div className="pt-3">
        <SkeletonUI type="radius-horizontal" height="20.438rem" />
      </div>
    </div>
  </div>
);

const PhotoSeriesDamages = ({ isLoadingPhotoSeries, damages, photoSeries, vehicleType }) => {
  const [schema, setSchema] = useState(null);
  const isCar = vehicleType === workflowsConstants.VEHICLE_TYPES.CAR;

  useEffect(() => {
    if (isCar && !isLoadingPhotoSeries) {
      makeGetFile(`photoseries/${photoSeries.id}/schema`)
        .subscribe(async ({ response }) => {
          setSchema(await response.text());
        });
    }
  }, [photoSeries, isCar, isLoadingPhotoSeries, photoSeries.damages]);

  if (isLoadingPhotoSeries) return <PhotoSeriesDamagesSkeleton />;
  return (
    <div className="photo-series-damages-container photo-series-card">
      <div className="photo-series-damages-container__row1">
        <div className="photo-series-damages-container__row1-col1">
          <span className="key">Vehicle damages</span>
          <span className="value">
            <VehicleDamagedIndicator damages={damages} />
          </span>
        </div>
      </div>
      <div className="photo-series-damages-container__row2">
        <div className="damages-table">
          <Damages damages={damages} vehicleType={vehicleType} photoSeries={photoSeries} />
        </div>
        {
          isCar && (
            schema !== null
              ? <div className="sliced-car" dangerouslySetInnerHTML={{ __html: schema }} />
              : <div style={{ width: '250rem' }}><SkeletonUI type="radius-horizontal" height="25rem" /></div>
          )
        }
      </div>
    </div>
  );
};

PhotoSeriesDamages.propTypes = {
  isLoadingPhotoSeries: PropTypes.bool.isRequired,
  damages: PropTypes.arrayOf(PropTypes.shape({
    bodyPart: PropTypes.number,
    damageType: PropTypes.number,
    customDamageTypeName: PropTypes.string
  })).isRequired,
  photoSeries: PropTypes.object,
  vehicleType: PropTypes.number.isRequired
};

export default PhotoSeriesDamages;
