import _ from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeGet } from '../../../../../../api/ajax';
import { isSafari } from '../../../../../../utils/browser';

import { ReactComponent as Eye } from '../../../../../../assets/img/icn-eye.svg';
import { ReactComponent as AddressOutlineIcon } from '../../../../../../assets/img/icn-address-outline.svg';
import { ReactComponent as WarningOutlineIcon } from '../../../../../../assets/img/icn-warning-outline.svg';
import { ReactComponent as CommentsOutlineIcon } from '../../../../../../assets/img/icn-comments-outline.svg';
import { ReactComponent as IconYellowWarning } from '../../../../../../assets/img/icn-warning-yellow.svg';
import { composeLocationApiUrl } from '../../../../../../api/composeApiUrl';
import { useBeforeUnload } from '../../../../../../hooks';
import { selectors as photoSeriesSelectors } from '../../../../../../redux/photoSeries';
import { selectors as workflowsSelectors, actions as workflowsActions } from '../../../../../../redux/workflows';
import { selectors as damagesSelectors, constants as damagesConstants } from '../../../../../../redux/damages';

import { authenticationService } from '../../../../../../services';
import { constants as sessionConstants } from '../../../../../../redux/session';
import { selectors as imagesSelectors, constants as imagesConstants, useImageTypeErrors } from '../../../../../../redux/images';
import { Loader, PhotoViewer, Sidebar, onHideHandler, renderShowConfirmationModal, } from '../../../../../../components';

import CommentsSection from './containers/CommentsSection/CommentsSection';
import DamagesSection from './containers/DamagesSection/DamagesSection';
import ImageCompareSearch from './containers/ImageCompareSearch/ImageCompareSearch';
import DeleteImage from './containers/DeleteImage/DeleteImage';
import AnalyzeImage from './containers/AnalyzeImage/AnalyzeImage';

import { useCanViewPhotoSeriesDamages } from '../../hooks/useCanViewPhotoSeriesDamages';

const ImageDetails = ({ image, onSwitchPhoto, onClose }) => {
  const dispatch = useDispatch();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [compareTargetSrc, setCompareTargetSrc] = useState(null);

  const zoomWrapperRef = useRef(null);

  const photoSeries = useSelector(photoSeriesSelectors.selectPhotoSeries)[image.photoSeriesId];
  const workflows = useSelector(workflowsSelectors.selectWorkFlows);
  const imageLocalUrls = useSelector(imagesSelectors.selectImageLocalUrls);

  const workflow = workflows && workflows[photoSeries?.workflow?.id];
  const gpsEnabled = workflow?.trackGeolocationEnabled;
  const gpsMissingAndEnabled = image?.location === null && gpsEnabled;

  const canViewPhotoSeriesDamages = useCanViewPhotoSeriesDamages(image.photoSeriesId);
  const damages = useSelector((state) => damagesSelectors.selectImageDamages(state, image.photoSeriesId, image.id));
  const allDamages = useSelector((state) => damagesSelectors.selectPhotoSeriesDamages(state, image.photoSeriesId));
  const imageTypeErrors = useImageTypeErrors();

  useEffect(() => {
    if (!workflows) {
      makeGet('workflows/currentCustomer/admin?showDeleted=true').subscribe(({ response }) => {
        dispatch(workflowsActions.loadWorkflowsSuccess(response));
      }, () => {
      });
    }
  }, [workflows, dispatch]);

  useBeforeUnload(hasUnsavedChanges);

  const imageLocalUrl = image && imageLocalUrls && imageLocalUrls[image.id];

  const handleSelectPhoto = useCallback((nextPhoto) => {
    if (zoomWrapperRef.current) zoomWrapperRef.current.reset();
    setZoom(1);
    onSwitchPhoto(nextPhoto);
    setCompareTargetSrc(null);
  }, [zoomWrapperRef, setZoom, onSwitchPhoto]);

  const handleClose = useCallback(() => {
    onHideHandler(hasUnsavedChanges, onClose, setShowConfirmModal);
  }, [hasUnsavedChanges, onClose, setShowConfirmModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // left arrow
        handleSelectPhoto(-1);
      }
      else if (event.keyCode === 39) {
        // right arrow
        handleSelectPhoto(1);
      }
      else if (event.keyCode === 27) {
        // escape
        handleClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSelectPhoto, handleClose]);

  const getImageName = (image) => {
    let name = `${imagesConstants.CAPTURE_NAMES[image.imageType]} capture`;
    if (image.imageSubType) name += `: ${imagesConstants.SUBTYPE_NAMES[image.imageSubType]}`;
    if (image.customImageSubType) {
      name += `: ${image.customImageSubType.name}`;
    }
    return name;
  };

  const withoutVisibilities = (arr) => _.filter(
    arr,
    (e) => imagesConstants.IMAGE_TYPE_VISIBILITY_ERRORS[imagesConstants.IMAGE_TYPES.EXTERIOR].indexOf(e.error) === -1
  );

  const onlyImageTypeErrors = (arr) => _.filter(arr, (e) => imageTypeErrors.indexOf(e.error) > -1);

  const renderImageErrors = (imageErrors) => {
    if (imageErrors?.length === 0) {
      return 'No errors found';
    }

    return (
      <ul>
        {
          onlyImageTypeErrors(withoutVisibilities(imageErrors)).map((errorDto, index) => {
            const errorText = imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[errorDto.error];
            return (
              <li key={index.toString()}>
                <div className="row">
                  <div className="col">
                    <IconYellowWarning className="mr-2" />
                    {
                      errorDto.errorDetails && errorDto.errorDetails.length > 0
                        ? <span title={errorDto.errorDetails}>{errorText}</span>
                        : <span>{errorText}</span>
                    }
                  </div>
                </div>
              </li>
            );
          })
        }
      </ul>
    );
  };

  const { hasPermission } = authenticationService;

  const visibilityError = imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[image.visibilityDetectionResult?.value];
  const dirtyText = (
    <div title={`Confidence: ${Math.fround(image.visibilityDetectionResult?.confidence * 100).toFixed(2)}%`}>
      <span className={visibilityError ? 'average-rating ml-1' : 'high-rating ml-1'}>
        {visibilityError || 'Clean'}
      </span>
    </div>);

  const renderCloseupDamageName = () => {
    if (image.imageType !== imagesConstants.IMAGE_TYPES.ADDITIONAL) return '';
    if (!image.relatedObjects || image.relatedObjects.length === 0) return '';

    const relatedObject = image.relatedObjects[0];
    const damage = allDamages.find((d) => d.damageId === relatedObject.id);

    if (!damage.customDamageType) return ` - ${damagesConstants.DAMAGE_TYPE_NAME[damage.damageType]}`;

    return ` - ${damage.customDamageType.name}`;
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        onClose();
        setShowConfirmModal(false);
      })}
      <PhotoViewer
        src={imageLocalUrl}
        compareTargetSrc={compareTargetSrc}
        onNextClick={() => handleSelectPhoto(1)}
        onPrevClick={() => handleSelectPhoto(-1)}
        onZoomChange={(value) => setZoom(value)}
        zoom={zoom}
        zoomWrapperRef={zoomWrapperRef}
        srcPolygons={canViewPhotoSeriesDamages() ? damages.filter((damage) => damage.show).map((damage) => damage.location) : []}
      >
        <Sidebar
          title={`${getImageName(image)}${renderCloseupDamageName()}`}
          onClose={handleClose}
          hidden={sidebarHidden}
          onToggleHideClick={() => setSidebarHidden(!sidebarHidden)}
          additionalActions={
            <div className="d-flex justify-content-start align-items-center w-100">
              {
                image.imageType !== imagesConstants.IMAGE_TYPES.ADDITIONAL && !isSafari() && (
                  <ImageCompareSearch
                    image={image}
                    setCompareTargetSrc={setCompareTargetSrc}
                  />
                )
              }
              <DeleteImage imageId={image.id} />
            </div>
          }
        >
          {(image?.location?.latitude || gpsEnabled) && (
            <Sidebar.Section>
              <Sidebar.Section.Header text="Location">
                <AddressOutlineIcon />
              </Sidebar.Section.Header>
              <Sidebar.Section.Body>
                {!gpsMissingAndEnabled
                  ? (
                    <a
                      href={composeLocationApiUrl(image.location)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="value map"
                    >
                      View on Google Maps
                    </a>
                  ) : (<span>GPS validation required but not available</span>)}
              </Sidebar.Section.Body>
            </Sidebar.Section>
          )}
          <AnalyzeImage photoSeriesId={image.photoSeriesId} imageType={image.imageType} />
          <Sidebar.Section className="error-list">
            <Sidebar.Section.Header text="Errors">
              <WarningOutlineIcon />
            </Sidebar.Section.Header>
            <Sidebar.Section.Body>
              {renderImageErrors(image.errors)}
            </Sidebar.Section.Body>
          </Sidebar.Section>
          <Sidebar.Section className="d-flex flex-column flex-grow-1 overflow-hidden">
            {image.visibilityDetectionResult
            && (
              <Sidebar.Section.Header text={<>Visibility: {dirtyText}</>}>
                <Eye className="svg-md" />
              </Sidebar.Section.Header>
            )}
          </Sidebar.Section>
          {
            canViewPhotoSeriesDamages(false) && (
              <DamagesSection
                photoSeriesId={image.photoSeriesId}
                imageId={image.id}
                damages={damages}
              />
            )
          }
          {hasPermission(sessionConstants.PERMISSION_TYPES.VIEW_COMMENTS) && (
            <Sidebar.Section className="d-flex flex-column flex-grow-1 overflow-hidden">
              <Sidebar.Section.Header text="Comments">
                {commentsLoading
                  ? <Loader size="tiny" isRelative noDelay />
                  : <CommentsOutlineIcon />}
              </Sidebar.Section.Header>
              <Sidebar.Section.Body className="d-flex flex-column flex-grow-1 overflow-hidden">
                <CommentsSection
                  photoSeriesId={image.photoSeriesId}
                  imageId={image.id}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setLoading={setCommentsLoading}
                />
              </Sidebar.Section.Body>
            </Sidebar.Section>
          )}
        </Sidebar>
      </PhotoViewer>
    </>
  );
};

ImageDetails.propTypes = {
  image: PropTypes.object.isRequired,
  onSwitchPhoto: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageDetails;
